<template>
  <div>
    <div class="container">
      <el-form ref="form" :model="queryInfo" label-width="120px">
        <el-card>
          <div class="form">
            <div class="comble_txt">
              <el-form-item label="意见类型:" prop="">
                <el-radio-group v-model="queryInfo.type" @change="changeSearchangeSearch">
                  <el-radio-button label="">全部 </el-radio-button>
                  <el-radio-button label="服务异常">服务异常 </el-radio-button>
                  <el-radio-button label="功能异常">功能异常 </el-radio-button>
                  <el-radio-button label="产品建议">产品建议 </el-radio-button>
                  <el-radio-button label="其他反馈">其他反馈 </el-radio-button>
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
        </el-card>
      </el-form>
      <el-card style="margin-top: 20px">
        <div class="input-with">
          <el-input
            placeholder="请输入意见内容"
            v-model="queryInfo.queryConditions"
            class="input-with-select"
          >
            <el-button class="seach" slot="append" @click="changeSearchangeSearch">搜索</el-button>
          </el-input>
        </div>
        <el-table
          v-loading="loading"
          element-loading-text="加载中..."
          element-loading-spinner="el-icon-loading"
          :data="listData"
          stripe
          style="width: 100%"
          class="in_table"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >
          <el-table-column label="编号" align="center" prop="id"></el-table-column>

          <el-table-column label="意见类型" sortable prop="type" align="center"></el-table-column>
          <el-table-column label="意见内容" align="center">
            <template v-slot="{ row }">
              <el-popover
                placement="top-start"
                title="意见内容"
                width="200"
                trigger="hover"
                :content="row.content"
              >
                <el-button type="text" slot="reference">{{ row.content }}</el-button>
              </el-popover>
              <!-- <span>{{ row.content }}</span> -->
            </template>
          </el-table-column>

          <el-table-column label="意见截图" prop="companyFullName" align="center">
            <template v-slot="{ row }">
              <div v-for="(o, index) in row.contentImages" :key="index">
                <el-image
                  style="width: 35px; height: 60px"
                  :preview-src-list="row.contentImages"
                  :src="o"
                  alt=""
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="提交人" prop="content" align="center">
            <template v-slot="{ row }">
              <span>{{ row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="联系方式" prop="phone" align="center"></el-table-column>
          <el-table-column label="提交时间" prop="createTime" align="center">
            <template v-slot="{ row }">
              <div>{{ row.createTime | date }}</div>
              <div>{{ row.createTime | time }}</div>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>

    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { feedBackPage } from '@/api/system'
import { pagination } from '@/mixins/pagination'
const defaultQueryInfo = Object.freeze({
  endTime: null,
  orderByParam: null,
  pageNum: 1,
  pageSize: 10,
  queryConditions: null,
  startTime: null,
  type: null
})
export default {
  name: 'Diagnosisrecord',
  mixins: [pagination],
  data() {
    return {
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      // 遮罩层
      loading: true,
      // 总条数
      total: 0
    }
  },
  created() {
    this.search()
  },
  methods: {
    //查询一览数据
    async search() {
      this.loading = true
      const { data: res } = await feedBackPage(this.queryInfo)
      if (res.resultCode === 200) {
        this.listData = res.data.list
        this.total = res.data.total
        this.loading = false
      }
    },

    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    },
    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  height: calc(100vh - 150px);
  overflow: auto;
}
.span_div {
  font-size: 14px;
  color: #7c7f8e;
  margin-right: 12px;
}

.input-with {
  width: 100%;
  overflow: hidden;

  .input-with-select {
    width: 400px;
    float: right;
  }

  .seach {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}

.in_table {
  width: 100%;
  margin-top: 20px;

  .img_url {
    width: 42px;
    height: 42px;
    border-radius: 4px;
  }
}

.new_page {
  margin: 20px auto;
  width: 100%;
  text-align: center;
}
</style>
